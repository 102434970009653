import React, { useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import * as styles from './graph.module.css'

const Graph = ({ options, updateData, topPad, title, summary, yAxis, trackItems, fineprint }) => {
    const [activeSpread, setActiveSpread] = useState('all');

    const manageClick = (spread) => {
        setActiveSpread(spread);
        updateData(spread);
    }

    return (
        <div className={`${styles.graphWrap} ${topPad ? styles.topPad : undefined}`}>
            <div className={styles.headBar}>
                <h2>{title}</h2>
                <div className={styles.spreadControls}>
                    <span onClick={() => manageClick('all')} className={activeSpread === 'all' ? styles.active : undefined}>All</span>
                    <span onClick={() => manageClick('1w')} className={activeSpread === '1w' ? styles.active : undefined}>1W</span>
                    <span onClick={() => manageClick('1m')} className={activeSpread === '1m' ? styles.active : undefined}>1M</span>
                    <span onClick={() => manageClick('3m')} className={activeSpread === '3m' ? styles.active : undefined}>3M</span>
                    {/* <span onClick={() => manageClick('6m')} className={activeSpread === '6m' ? styles.active : undefined}>6M</span>
                    <span onClick={() => manageClick('1y')} className={activeSpread === '1y' ? styles.active : undefined}>1Y</span>
                    <span onClick={() => manageClick('3y')} className={activeSpread === '3y' ? styles.active : undefined}>3Y</span> */}
                </div>
            </div>
            {summary && (
                <div className={styles.summary}>
                    {summary.map((s, sI) => (
                        <span key={sI}>
                            <span className={styles[s.color]}>{s.value}</span> {s.label}
                        </span>
                    ))}
                </div>
            )}
            {yAxis && (
                <div className={styles.yAxis}>
                    <span>y-axis: </span><select>
                        {yAxis.map((a, aI) => (
                            <option key={aI} value={a}>{a}</option>
                        ))}
                    </select>
                </div>
            )}
            <div className={styles.graph}>
                {/* <img src="dummy-graph.png" alt="dummy graph" /> */}
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={options}
                />
            </div>
            {trackItems && (
                <div className={styles.key}>
                    <div className={styles.items}>
                        {trackItems.map((i, iI) => (
                            <span key={iI}>
                                <span className={styles[i.color]}></span> {i.label}
                            </span>
                        ))}
                    </div>
                    {fineprint && (
                        <div className={styles.fineprint}>{fineprint}</div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Graph