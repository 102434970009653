import React, { useState } from 'react';
import Container from '../container'
import Datatable from '../datatable/datatable';
// import Graph from '../graph/graph';
import Button from '../button/button';

import * as styles from './cloudIndexStats.module.css'

const CloudIndexStats = ({data}) => {
    const [compare, setCompare] = useState(null);
    const [date, setDate] = useState('April 28, 2023');

    const round = (value, precision) => {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    const updateCompare = (spread = 'all') => {
        const end = data.redpoint.nodes.length;
        let start = 0;
        switch(spread) {
            case '1w':
                start = end - 7;
                break;

            case '1m':
                start = end - 30;
                break;

            case '3m':
                start = end - 91;
                break;

            case '6m':
                start = end - 183;
                break;

            case '1y':
                start = end - 365;
                break;

            case '3y':
                start = end - 1095;
                break;

            default:
                start = 0;
                break;
        }

        if (start < 0) start = 0;

        const theDate = new Date(data.redpoint.nodes[start].date.replace(' 16:00:00', ''));
        const months = [
            'January',
            'Feburary',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        const dateOrdinal = (d) => {
            return d+(31==d||21==d||1==d?"st":22==d||2==d?"nd":23==d||3==d?"rd":"th")
        };
        setDate(`${months[theDate.getMonth()]} ${dateOrdinal(theDate.getDate())}, ${theDate.getFullYear()}`);
        setCompare({
            redpoint: {
                today: data.redpoint.nodes[end - 1].close,
                date: data.redpoint.nodes[start].close,
                change: (((data.redpoint.nodes[end - 1].close - data.redpoint.nodes[start].close) / data.redpoint.nodes[start].close) * 100)
            },
            nasdaq: {
                today: data.nasdaq.nodes[end - 1].close,
                date: data.nasdaq.nodes[start].close,
                change: (((data.nasdaq.nodes[end - 1].close - data.nasdaq.nodes[start].close) / data.nasdaq.nodes[start].close) * 100)
            },
            snp500: {
                today: data.snp500.nodes[end - 1].close,
                date: data.snp500.nodes[start].close,
                change: (((data.snp500.nodes[end - 1].close - data.snp500.nodes[start].close) / data.snp500.nodes[start].close) * 100)
            },
            dowjones: {
                today: data.dowjones.nodes[end - 1].close,
                date: data.dowjones.nodes[start].close,
                change: (((data.dowjones.nodes[end - 1].close - data.dowjones.nodes[start].close) / data.dowjones.nodes[start].close) * 100)
            }
        })
    }

    if (compare === null) {
        updateCompare('all');
    }

    return (
        <div className={`${styles.stats} off`}>
            <Container class={styles.container}>
                <p className={styles.intro}>The Nasdaq Redpoint Cloud Infrastructure Software Index&trade; is designed to track the performance of a selection of companies that derive the majority of its revenue from selling cloud-native infrastructure software.</p>
                <div className={styles.callouts}>
                    <div>
                        <span className={styles.statValue}>{data.settings.nodes[0].totalMarketCap}</span>
                        <span className={styles.statMarker}></span>
                        <span className={styles.statDescription}>Total Market Cap</span>
                    </div>
                    {/* <div>
                        <span className={styles.statValue}>{data.settings.nodes[0].medianRevenueMultiple}</span>
                        <span className={styles.statMarker}></span>
                        <span className={styles.statDescription}>Median Revenue Multiple</span>
                    </div> */}
                    <div>
                        <span className={styles.statValue}>{data.settings.nodes[0].medianRevenueGrowthRate}</span>
                        <span className={styles.statMarker}></span>
                        <span className={styles.statDescription}>Median Revenue Growth Rate</span>
                    </div>
                </div>
                
                {compare && (
                    <Datatable
                        topPad={true}
                        title="Compare"
                        headers={[
                            'Index',
                            'Today',
                            'Date',
                            'Change'
                        ]}
                        date={date}
                        update={updateCompare}
                        rows={[
                            {Index: "NQRPCI", Today: `${compare.redpoint.today.toLocaleString('en-US')}`, Date: `${compare.redpoint.date.toLocaleString('en-US')}`, Change: `${round(compare.redpoint.change, 1)}%`},
                            {Index: "NASDAQ", Today: `${compare.nasdaq.today.toLocaleString('en-US')}`, Date: `${compare.nasdaq.date.toLocaleString('en-US')}`, Change: `${round(compare.nasdaq.change, 1)}%`},
                            {Index: "S&P 500", Today: `${compare.snp500.today.toLocaleString('en-US')}`, Date: `${compare.snp500.date.toLocaleString('en-US')}`, Change: `${round(compare.snp500.change, 1)}%`},
                            {Index: "DOW JONES", Today: `${compare.dowjones.today.toLocaleString('en-US')}`, Date: `${compare.dowjones.date.toLocaleString('en-US')}`, Change: `${round(compare.dowjones.change, 1)}%`},
                        ]}
                    />
                )}

                {/* <Graph 
                    topPad={true}
                    title="Key Cloud Index Metrics"
                    yAxis={[
                        "Forward Revenue Multiple", 
                        "Revenue Multiple", 
                        "Revenue Growth Rate", 
                        "Efficiency Score", 
                        "LTM FCF Margin",
                        "Gross Margin"
                    ]}
                    trackItems={[
                        {color: "rpb", label: "Median"},
                        {color: "mrb", label: "Top Quartile"},
                        {color: "dbb", label: "Bottom Quartile"},
                    ]}
                    fineprint={["Lipsum"]}
                /> */}

                <div className={styles.banner}>
                    <span>Read the InfraRed Report</span>
                    <Button link="/infrared/report/" size="short" version='1'>View Report</Button>
                </div>
            </Container>
        </div>
    )
}

export default CloudIndexStats