import React from 'react';
import Container from '../container'

import report from '../../img/infrared-report.png'
import list from '../../img/infrared-100.png'

import * as styles from './cloudIndexResources.module.css'

const ResourceCard = ({img, title, blurb, link}) => (
    <div className={styles.card}>
        <a href={link}></a>
        <div className={styles.cardImage} style={{backgroundImage: `url(${img})`}}></div>
        <div className={styles.cardTitle}>{title}</div>
        <div className={styles.cardBlurb}>{blurb}</div>
    </div>
)

const CloudIndexResources = ({withWave, theme}) => {
    return (
        <div className={`${styles.resources} ${withWave && styles.withWave} ${theme ? styles[theme] : styles.offwhite}`}>
            <Container class={styles.container}>
                <h5>Top Resources</h5>
                <div className={styles.resourceCards}>
                    <ResourceCard
                        img={report}
                        title="The InfraRed Report"
                        blurb="Redpoint Ventures' report on the State of Cloud Infrastructure in 2023"
                        link="/infrared/report/"
                    />
                    <ResourceCard
                        img={list}
                        title="InfraRed 100"
                        blurb="The first list to celebrate the top 100 private companies in Cloud Infrastructure"
                        link="/infrared/100/"
                    />
                </div>
            </Container>
        </div>
    )
}

export default CloudIndexResources