import React, {useState} from 'react';
import Container from '../container'
import Graph from '../graph/graph';

import * as styles from './cloudIndexPerformance.module.css'

const CloudIndexPerformance = ({data}) => {
    const [options, setOptions] = useState(null);
    const [overview, setOverview] = useState({
        redpoint: 0,
        nasdaq: 0,
        snp500: 0,
        dowjones: 0
    })

    const months = [
        'January',
        'Feburary',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]

    const round = (value, precision) => {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    const updateData = (spread = 'all') => {
        const end = data.redpoint.nodes.length;
        let start = 0;
        switch(spread) {
            case '1w':
                start = end - 7;
                break;

            case '1m':
                start = end - 30;
                break;

            case '3m':
                start = end - 91;
                break;

            case '6m':
                start = end - 183;
                break;

            case '1y':
                start = end - 365;
                break;

            case '3y':
                start = end - 1095;
                break;

            default:
                start = 0;
                break;
        }

        if (start < 0) start = 0;

        const redpoint = data.redpoint.nodes.slice(start, end).map(day => ([new Date(day.date).getTime(), (((day.close - data.redpoint.nodes[start].close) / data.redpoint.nodes[start].close) * 100)]));
        const nasdaq = data.nasdaq.nodes.slice(start, end).map(day => ([new Date(day.date).getTime(), (((day.close - data.nasdaq.nodes[start].close) / data.nasdaq.nodes[start].close) * 100)]));
        const snp500 = data.snp500.nodes.slice(start, end).map(day => ([new Date(day.date).getTime(), (((day.close - data.snp500.nodes[start].close) / data.snp500.nodes[start].close) * 100)]));
        const dowjones = data.dowjones.nodes.slice(start, end).map(day => ([new Date(day.date).getTime(), (((day.close - data.dowjones.nodes[start].close) / data.dowjones.nodes[start].close) * 100)]));

        const _options = {
            yAxis: {
                labels: {
                    formatter: function() {
                        return this.value + ' %';
                    }
                },
            },
            navigator: {
                enabled: false,
            },
            scrollbar: {
                enabled: false,
            },
            rangeSelector: {
                enabled: false,
            },
            tooltip: {
                formatter: function () {
                    const date = new Date(this.x);
                    return [`<b>${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}</b>`].concat(
                        this.points ?
                            this.points.map(function (point) {
                                return `${point.series.name}: ${round(point.y, 1)}%`;
                            }) : []
                    );
                },
                split: true
            },
            series: [
                {
                    name: "NQRPCI",
                    color: "#FF0E1D",
                    data: redpoint
                },
                {
                    name: "NASDAQ",
                    color: "#66B8C7",
                    data: nasdaq
                },
                {
                    name: "S&P 500",
                    color: "#D9A35B",
                    data: snp500
                },
                {
                    name: "Dow Jones",
                    color: "#79A28A",
                    data: dowjones
                }
            ]
        }

        setOptions(_options);
        setOverview({
            redpoint: redpoint[redpoint.length - 1][1],
            nasdaq: nasdaq[nasdaq.length - 1][1],
            snp500: snp500[snp500.length - 1][1],
            dowjones: dowjones[dowjones.length - 1][1],
        })
    }

    if (options === null) {
        updateData('all');
    }

    return (
        <div className={`${styles.performance} off-wave`}>
            <Container class={styles.container}>
                <h1 className={styles.headline}>The Nasdaq Redpoint Cloud Infrastructure Software Index&trade;<span>(NQRPCI)</span></h1>
                {options && (
                    <Graph 
                        title="Index Performance"
                        summary={[
                            {color: "rpt", value: `${overview.redpoint > 0 ? '+' : ''}${round(overview.redpoint, 1)}%`, label: "NQRPCI"},
                            {color: "ndt", value: `${overview.nasdaq > 0 ? '+' : ''}${round(overview.nasdaq, 1)}%`, label: "NASDAQ"},
                            {color: "spt", value: `${overview.snp500 > 0 ? '+' : ''}${round(overview.snp500, 1)}%`, label: "S&P 500"},
                            {color: "djt", value: `${overview.dowjones > 0 ? '+' : ''}${round(overview.dowjones, 1)}%`, label: "Dow Jones"},
                        ]}
                        trackItems={[
                            {color: "rpb", label: "NQRPCI"},
                            {color: "ndb", label: "NASDAQ"},
                            {color: "spb", label: "S&P 500"},
                            {color: "djb", label: "Dow Jones"},
                        ]}
                        options={options}
                        updateData={updateData}
                        fineprint={["For complete index information including methodology, visit ", <a key="link" href="https://indexes.nasdaqomx.com/Index/History/NQRPCI" target="_blank">NASDAQ</a>, "."]}
                    />
                )}
            </Container>
        </div>
    )
}

export default CloudIndexPerformance