import React, {useState} from 'react';

import * as styles from './datatable.module.css'

const Datatable = ({ topPad, title, headers, date, rows, update }) => {
    const [activeSpread, setActiveSpread] = useState('all');

    const manageClick = (spread) => {
        setActiveSpread(spread);
        update(spread);
    }

    return (
        <div className={`${styles.tableWrap} ${topPad ? styles.topPad : undefined}`}>
            <div className={styles.headBar}>
                <h2>{title}</h2>
                <div className={styles.spreadControls}>
                <span onClick={() => manageClick('all')} className={activeSpread === 'all' ? styles.active : undefined}>All</span>
                    <span onClick={() => manageClick('1w')} className={activeSpread === '1w' ? styles.active : undefined}>1W</span>
                    <span onClick={() => manageClick('1m')} className={activeSpread === '1m' ? styles.active : undefined}>1M</span>
                    <span onClick={() => manageClick('3m')} className={activeSpread === '3m' ? styles.active : undefined}>3M</span>
                    {/* <span onClick={() => manageClick('6m')} className={activeSpread === '6m' ? styles.active : undefined}>6M</span>
                    <span onClick={() => manageClick('1y')} className={activeSpread === '1y' ? styles.active : undefined}>1Y</span>
                    <span onClick={() => manageClick('3y')} className={activeSpread === '3y' ? styles.active : undefined}>3Y</span> */}
                </div>
            </div>
            <div className={styles.table}>
                <table>
                    <thead>
                        <tr>
                            <th className={styles.gap}></th>
                            {headers.map((h, hI) => (
                                <th key={hI}>{h === 'Date' ? date : h}</th>
                            ))}
                            <th className={styles.gap}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((r, rI) => (
                            <tr key={rI}>
                                <td className={styles.gap}></td>
                                {headers.map((h, hI) => (
                                    <td key={hI}>{r[h]}</td>
                                ))}
                                <td className={styles.gap}></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Datatable